import React, { useState } from "react";
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useToast,
    Center,
    Button,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Nav, NavLogo } from "./AdminNav.elements";
import { useAuth } from "../../context/AuthContext";
import { Logo } from "../Logo";
import PasswordChangeModal from "../../pages/auth/components/PasswordChangeModal";
import ProfileChangeModal from "../../pages/auth/components/ProfileChangeModal";
import { adminRoutes } from "../../routes/Data";

export default function AdminNavigation() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [passChange, setPassChange] = useState(false);
    const [profile, setProfile] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();
    const { logout, token } = useAuth();

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };

    const myBasicAccount = useQuery(
        [`myBasicAccount`, token],
        async () =>
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}adminAuth/me`, {
                headers,
            }),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            retry: false && !!token,
            enabled: !!token,
            onError: (res) => {
                if (token && res?.response?.status === 401) {
                    logout();
                    navigate("/login");
                    toast.closeAll();
                    toast({
                        title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            },
            onSuccess: (res) => {
                toast.closeAll();
            }
        }
    );
    const onProfileExit = () => {
        setPassChange(false);
        setProfile(false);
        onClose();
    };

    return (
        <Box minH="100vh" bg={"#f3fff2"} overflow="hidden">
            <PasswordChangeModal isOpen={passChange} onClose={onProfileExit} />
            <ProfileChangeModal isOpen={profile} onClose={onProfileExit} />
            <SidebarContent
                onClose={onClose}
                display={{ base: "none", lg: "block" }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
            >
                <DrawerContent>
                    {!myBasicAccount.isLoading && myBasicAccount?.isSuccess && (
                        <SidebarContent onClose={onClose} />
                    )}
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav
                myBasicAccount={myBasicAccount}
                setPassChange={setPassChange}
                setProfile={setProfile}
                onOpen={onOpen}
            />
            <Box ml={{ base: 0, lg: 60 }}>
                <Outlet />
            </Box>
        </Box>
    );
}

const SidebarContent = ({ onClose, ...rest }) => {
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue("white", "gray.900")}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", lg: 60 }}
            pos="fixed"
            h="full"
            {...rest}
            overflowY="auto"
        >
            <Flex
                onClick={onClose}
                h="20"
                alignItems="center"
                pr="10px"
                justifyContent="center"
            >
                <NavLogo to="/dashboard">
                    <Center>
                        <Logo />
                    </Center>
                </NavLogo>
                <CloseButton display={{ base: "flex", lg: "none" }} onClick={onClose} />
            </Flex>
            {adminRoutes
                .filter((route) => route.sideBarVisible)
                .map((link, index) => (
                    <NavItem
                        to={link?.path}
                        key={index}
                        onClose={onClose}
                        icon={link.icon}
                    >
                        {link?.name}
                    </NavItem>
                ))}
        </Box>
    );
};

const NavItem = ({ icon, children, onClose, to, ...rest }) => {
    const location = useLocation();

    return (
        <Link to={to} _focus={{ boxShadow: "none" }}>
            <Flex
                onClick={onClose}
                style={{
                    background:
                        location?.pathname.toLowerCase().startsWith(to.toLowerCase()) ||
                            location?.pathname.toLowerCase() === `${to.toLowerCase()}/`
                            ? "#9DB94C"
                            : "initial",
                    color:
                        location?.pathname.toLowerCase().startsWith(to.toLowerCase()) ||
                            location?.pathname.toLowerCase() === `${to.toLowerCase()}/`
                            ? "#FFF"
                            : "initial",
                }}
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                bg={"#FFF"}
                cursor="pointer"
                {...rest}
            >
                <HStack>
                    {icon}
                    <Text>{children}</Text>
                </HStack>
            </Flex>
        </Link>
    );
};

const MobileNav = ({
    onOpen,
    setPassChange,
    setProfile,
    count,
    myBasicAccount,
    ...rest
}) => {
    const { logout } = useAuth();

    const onLogout = () => {
        logout();
    };

    return (
        <Nav>
            <Flex
                ml={{ base: 0, lg: 60 }}
                px={{ base: 4, lg: 4 }}
                height="70px"
                alignItems="center"
                bg={useColorModeValue("white", "gray.900")}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue("gray.200", "gray.700")}
                justifyContent={{ base: "space-between", lg: "flex-end" }}
                {...rest}
                overflow=" hidden"
                background-color=" #333"
                zIndex={800}
            >
                <IconButton
                    display={{ base: "flex", lg: "none" }}
                    onClick={onOpen}
                    variant="outline"
                    aria-label="open menu"
                    icon={<FiMenu />}
                />
                <Box display={{ base: "flex", lg: "none" }}>
                    <Logo />
                </Box>
                <HStack spacing={{ base: "0", lg: "6" }}>
                    <Flex alignItems={"center"}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: "none" }}
                            >
                                <Box pr="5px">
                                    <Avatar
                                        size={"md"}
                                        src={`${process.env.REACT_APP_FILE_URL}${myBasicAccount?.data?.data?.data?.avatar}`}
                                    />
                                </Box>                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        setProfile(true);
                                    }}
                                >
                                    Profile
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setPassChange(true);
                                    }}
                                >
                                    Change Password
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                    onClick={onLogout}
                                >
                                    Sign out
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </Nav>
    );
};
