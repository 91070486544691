import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
// import {
//   DateTimePicker,
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import { BiHide, BiShow } from "react-icons/bi";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { BsCurrencyDollar } from "react-icons/bs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomInput = ({
  field,
  title,
  htmlFor,
  type,
  error,
  touched,
  extra,
  options,
  form,
  myDefault,
  isMulti,
  maxDate,
  minDate,
  exclude
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [showPassword, setPassword] = useState(false);

  return (
    <Box
      w={
        extra === "password"
          ? "300px"
          : { base: "100%", md: "70%", lg: "40%", xl: "40%", "2xl": "40%" }
      }
    >
      <FormControl isInvalid={error && touched}>
        <Stack maxW="370px" spacing="0px">
          <FormLabel htmlFor={htmlFor}>
            <Text display={extra === "checkbox" ? "none" : "flex"} w="260px">
              {title}
            </Text>
          </FormLabel>
          {extra === "select" ? (
            <Stack
              w={{
                base: "full",
              }}
              minW="300px"
            >
              <Box border="1px solid #00953B" borderRadius={"5px"}>
                <Select
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: "#9DB94C",
                    },
                  })}
                  getOptionLabel={(option) => option?.title}
                  isClearable={true}
                  isMulti={isMulti ?? false}
                  getOptionValue={(option) => option?._id}
                  value={field.value}
                  defaultValue={myDefault}
                  options={
                    exclude ?
                      options.filter((option) => option.title !== exclude)
                      : isMulti
                        ? [{ name: "Select All", id: "all" }, ...options]
                        : options
                  }
                  onChange={(newSelection) => {
                    form?.setFieldValue(
                      htmlFor,
                      newSelection?.[newSelection?.length - 1]?.id === "all"
                        ? options
                        : newSelection
                    );
                  }}
                />
              </Box>
            </Stack>
          ) : extra === "phone" ? (
            <Input
              minW="300px"
              // onInput={(e) => {
              //   e.target.value = Math.max(0, parseInt(e.target.value))
              //     .toString()
              //     .slice(0, 9);
              // }}
              style={{
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
              fontSize={{
                base: "18px",
                sm: "14px",
                md: "15px",
                lg: "15px",
                xl: "15px",
                "2xl": "18px",
              }}
              {...field}
              type="number"
              className="form-control"
              placeholder="Phone Number"
            />
          ) : extra === "Br" ? (
            <InputGroup minW="270px">
              <InputLeftAddon
                px="10px"
                children={<BsCurrencyDollar size="1.2rem" />}
              />
              <Input
                focusBorderColor="#2B6CB0"
                type="number"
                min="0"
                onChange={(e) => {
                  let val = parseInt(e.target.value, 10);
                  if (isNaN(val)) {
                    this.setState({ number: "" });
                  } else {
                    // is A Number
                    val = val >= 0 ? val : 0;
                    this.setState({ number: val });
                  }
                }}
                {...field}
                id={htmlFor}
                placeholder={title}
              />
            </InputGroup>
          ) : extra === "date" ? (
            <Stack>
              {/* <Text fontWeight="bold">Date of birth</Text> */}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  ampm={false}
                  style={{
                    width: 230,
                    alignSelf: "start",
                  }}
                  maxDate={maxDate}
                  minDate={minDate}
                  id="date-picker-dialog"
                  placeholder=""
                  value={field?.value}
                  onChange={(newSelection) => {
                    form?.setFieldValue(field?.name, newSelection);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider> */}
            </Stack>
          ) : extra === "password" ? (
            <InputGroup size="md">
              {htmlFor === "password" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "confirmPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}{" "}
              {htmlFor === "oldPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showOldPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "newPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showNewPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "confirmNewPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}
              <InputRightElement width="4.5rem">
                {" "}
                {htmlFor === "password" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "oldPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "newPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "confirmNewPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}{" "}
                {htmlFor === "confirmPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
              </InputRightElement>
            </InputGroup>
          ) : extra === "checkbox" ? (
            <Stack w={"350px"} justify={"center"}>
              <Checkbox
                defaultChecked={field?.value ? true : false}
                {...field}
              >
                {title}
              </Checkbox>
            </Stack>
          ) : extra === "desc" ? (
            <Stack
              w={{
                base: "full",
                md: "540px",
              }}
            >
              <ReactQuill
                {...field}
                theme={"snow"}
                onChange={(value) => {
                  form?.setFieldValue('description', value);
                }}
              />
              {/* <CKEditor
                editor={ClassicEditor}
                data={field?.value}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  form?.setFieldValue('description', editor.getData());
                }}
                onBlur={(event, editor) => { }}
                onFocus={(event, editor) => { }}
              /> */}
            </Stack>
          ) : (
            <Input
              focusBorderColor="#9DB94C"
              w={{ base: "100%", sm: "400px" }}
              {...field}
              id={htmlFor}
              placeholder={title}
              type={type}
            />
          )}
          {error && touched && (
            <Text w="300px" noOfLines={1} maxH={"25px"} color="red">
              {error}
            </Text>
          )}
        </Stack>
      </FormControl>
    </Box>
  );
};
export default CustomInput;
